import { Recipe } from 'api/definitions/recipes/index.d';
import { convertDisplayPriceToNumber } from '../../../../utils/parse-display-price';
import { RecipeStateItem, RecipeStateItemError, RecipesRoot } from '../reducers';
import { IngredientGroups } from '../index.d';

export interface GetRecipeType extends RecipeStateItem {
  metaDescription: NonNullable<RecipeStateItem['metaDescription']>;
}

export const getRecipe = ({ recipes }: RecipesRoot): GetRecipeType => {
  const recipe: RecipeStateItem | RecipeStateItemError = recipes[recipes.id] || {};
  return {
    ...(recipes as unknown as RecipeStateItem),
    cookingTime: recipe.cookingTime,
    isShoppable: (!(recipe as unknown as RecipeStateItemError).pimsError &&
      recipes.isShoppable) as RecipeStateItem['isShoppable'],
    metaDescription: recipe.metaDescription || recipe.description?.plainText || '',
    products: recipe.products,
    rating: recipe.rating,
  };
};

export const getRecipeById =
  (id: Recipe['id']) =>
  ({ recipes }: RecipesRoot) => {
    return recipes?.[id];
  };

export const getLineNumbers = ({ recipes }: RecipesRoot) =>
  (recipes[recipes.id]?.products || []).map(
    /* @ts-expect-error TODO */
    ingredient => ingredient.products[0]?.lineNumber,
  );

export const getShoppableProducts = ({ recipes }: RecipesRoot, recipeId?: Recipe['id']) => {
  const recipe = recipes[recipeId || recipes.id];

  return recipe ? recipe.shoppableProducts : null;
};

export const getMultipleRecipesShoppableProducts = (
  state: RecipesRoot,
  recipeIds: (Recipe['id'] | undefined)[],
) =>
  recipeIds.reduce<IngredientGroups[]>((shoppableProducts, recipeId) => {
    const recipe = state.recipes[recipeId as unknown as Recipe['id']];
    return recipe ? shoppableProducts.concat(recipe.shoppableProducts) : shoppableProducts;
  }, []);

export const getNumberOfSelectedShoppableProducts = (
  { recipes }: RecipesRoot,
  recipeId?: Recipe['id'],
) => {
  const recipe = recipes[recipeId || recipes.id] || {};
  const { shoppableProducts } = recipe;

  return shoppableProducts?.length
    ? shoppableProducts.reduce((total, { ingredients }) => {
        const { storeCupboard, nonStoreCupboard } = ingredients;
        return (
          total +
          storeCupboard!.filter(ingredient => ingredient.amountSelected > 0).length + // eslint-disable-line @typescript-eslint/no-non-null-assertion
          nonStoreCupboard!.filter(ingredient => ingredient.amountSelected > 0).length // eslint-disable-line @typescript-eslint/no-non-null-assertion
        );
      }, 0)
    : 0;
};

export const getNumberOfShoppableProducts = ({ recipes }: RecipesRoot, recipeId?: Recipe['id']) => {
  const recipe = recipes[recipeId || recipes.id] || {};
  const { shoppableProducts } = recipe;

  return shoppableProducts?.length > 0
    ? shoppableProducts.reduce((total, { ingredients }) => {
        const { storeCupboard, nonStoreCupboard } = ingredients;
        return total + storeCupboard.length + nonStoreCupboard.length;
      }, 0)
    : 0;
};

export const getNumberOfSelectedShoppableProductsForMultipleRecipes = (
  state: RecipesRoot,
  recipeIds: (Recipe['id'] | undefined)[],
) =>
  recipeIds.reduce(
    (total, recipeId) => total + getNumberOfSelectedShoppableProducts(state, recipeId),
    0,
  );

export const getNumberOfShoppableProductsForMultipleRecipes = (
  state: RecipesRoot,
  recipeIds: (Recipe['id'] | undefined)[],
) =>
  recipeIds.reduce((total, recipeId) => total + getNumberOfShoppableProducts(state, recipeId), 0);

export const getTotalPriceOfSelectedShoppableProducts = (
  { recipes }: RecipesRoot,
  recipeId?: Recipe['id'],
) => {
  const recipe = recipes[recipeId || recipes.id] || {};
  const { shoppableProducts } = recipe;

  return shoppableProducts?.length
    ? shoppableProducts.reduce((total, { ingredients }) => {
        const { storeCupboard, nonStoreCupboard } = ingredients;
        return (
          total +
          storeCupboard.reduce((storeTotal, ingredient) => {
            /* @ts-expect-error TODO */
            return storeTotal + (convertDisplayPriceToNumber(ingredient?.totalPrice) || 0);
          }, 0) +
          nonStoreCupboard.reduce((nonStoreTotal, ingredient) => {
            /* @ts-expect-error TODO */
            return nonStoreTotal + (convertDisplayPriceToNumber(ingredient?.totalPrice) || 0);
          }, 0)
        );
      }, 0)
    : 0;
};

export const getRecipesTotalPriceOfSelectedShoppableProducts = (
  state: RecipesRoot,
  recipeIds: (Recipe['id'] | undefined)[],
) =>
  recipeIds.reduce(
    (total, recipeId) => total + getTotalPriceOfSelectedShoppableProducts(state, recipeId),
    0,
  );

export const getNumberOfRecipesWithShoppableProducts = (
  state: RecipesRoot,
  recipeIds: (Recipe['id'] | undefined)[],
) =>
  recipeIds.reduce(
    (total, recipeId) => (getNumberOfShoppableProducts(state, recipeId) > 0 ? total + 1 : total),
    0,
  );
