import React from 'react';
import propTypes from 'prop-types';
import { CmsComponentGrid } from '@johnlewispartnership/wtr-content-component-library';

import AemComponentGrid from 'components/AemComponentGrid';
import useComponentLibraryDecision from 'content/hooks/useComponentLibraryDecision';

const ContentLocation = ({ preloadImages = false, ...props }) => {
  const useLibrary = useComponentLibraryDecision();

  const ComponentGrid = useLibrary ? CmsComponentGrid : AemComponentGrid;

  return <ComponentGrid {...props} asContentLocation preloadImages={preloadImages} />;
};

ContentLocation.defaultProps = {
  name: 'content-location',
  preloadImages: false,
};

ContentLocation.propTypes = {
  name: propTypes.string,
  preloadImages: propTypes.bool,
};

export default ContentLocation;
