export interface ExperienceFragmentConfig {
  key: string;
  location: string;
}

export const MIDDLE_MEGA_MENU = {
  key: 'menu/middle-mega-menu/master',
  location: 'middleMegaMenu',
} as const satisfies ExperienceFragmentConfig;

export const SEASONAL_MENU = {
  key: 'menu/seasonal-menu/seasonal-menu-content/master',
  location: 'seasonalMenu',
} as const satisfies ExperienceFragmentConfig;

export const SITE_WIDE_BANNER = {
  key: 'Site-Wide-Banner/Site-Banner-Content/master',
  location: 'siteWideBannerContent',
} as const satisfies ExperienceFragmentConfig;

export const FAVOURITES_AEM_CAROUSEL_EXPERIENCE_FRAGMENT = {
  key: 'favourites-carousel/favourites-carousel-experience-fragment/master',
  location: 'favourites',
} as const satisfies ExperienceFragmentConfig;
