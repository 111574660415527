import { useExperiments } from 'components/Experiment/useExperiments';
import { AEM_CI_CONTENT_COMPONENT_LIBRARY } from 'constants/monetateExperiments';

const useComponentLibraryDecision = () => {
  const { getDecisionById } = useExperiments();
  const { variant } = getDecisionById(AEM_CI_CONTENT_COMPONENT_LIBRARY.experiment);

  return variant === AEM_CI_CONTENT_COMPONENT_LIBRARY.variant.useLibrary;
};

export default useComponentLibraryDecision;
