import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { HelmetProvider } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { ShortcutManager } from 'react-shortcuts';
import { config } from 'content/components/config';
import { ContentComponentLibraryProvider } from '@johnlewispartnership/wtr-content-component-library';
import classnames from 'classnames';
import AppBanner from 'components/AppBanner';
import CookieAlert from 'components/CookieAlert';
import DataProvider from 'components/DataProvider';
import SiteFooter from 'components/Footer/SiteFooter';
import SiteFooterMinimal from 'components/Footer/SiteFooterMinimal';
// This imports should be lazy loaded but we are having problems with the CLS score because of the SSR
import HomePage from 'components/HomePage';
import CommonModal from 'components/Modal/CommonModal';
import OptimisticTrolleyHandler from 'components/OptimisticTrolleyHandler';
import { SimpleErrorBoundary } from 'components/SimpleErrorBoundary/SimpleErrorBoundary';
import SiteHeader from 'components/SiteHeader';
import SiteHeaderMinimal from 'components/SiteHeader/SiteHeaderMinimal';
import Skeleton from 'components/Skeleton';
import { loadable } from 'components/Skeleton/LoadableSkeleton'; // This HAS to be called `loadable` to work with SSR
import WebVitalsReporter from 'components/WebVitalsReporter';
import ExperimentsReporter from 'components/Experiment/ExperimentsReporter';
import TrolleyAnalyticsReporter from 'components/Trolley/TrolleyAnalyticsReporter';
import ErrorBoundary from 'components/ErrorBoundary';
import RefreshMessaging from 'components/RefreshMessaging';
import { Head } from 'components/App/Head';
import { WebviewApp } from 'components/App/WebviewApp';
import { keymap } from 'constants/keymap';
import {
  pagesWithCheckoutScaffolding,
  pagesWithMinimalScaffolding,
  pagesWithoutAmendBar,
} from 'constants/pageScaffolding';
import urls, { cmsUrls } from 'constants/urls';
import { log } from 'utils/error-logging';
import { getFeatureFlags } from 'utils/feature-flags';
import { rootPath } from 'utils/root-path';
import { getAppType } from 'utils/webview';
import { CheckoutWrapper } from 'components/NewCheckout/CheckoutWrapper';
import { MealPlanner as MealPlannerComponent } from 'components/Recipes/MealPlanner';
import { hasCustomerAccountsInitialLoadCompleted } from 'redux/modules/accounts/selectors';
import { hasCustomerSlotInitialLoadCompleted } from 'redux/modules/customer-slot/selectors';
import { hasGetMembershipStatusInitialLoadCompleted } from 'redux/modules/marketing-preferences/selectors/get-membership-status';
import { hasCustomerOrderStatsInitialLoadCompleted } from 'redux/modules/orders/selectors';
import hasTrolleyLoadCompleted from 'redux/modules/trolley/selectors/get-loaded';
import { withBlockingApis } from 'components/withBlockingApis';

import 'components/NewCheckout/CheckoutHeader/styles.scss'; // must be in the main bundle.css to fix the checkout header SSR

import styles from './App.scss';

/**
 * Includes selectors matching to all apis src/redux/modules/sessions/get-customer-data-if-logged-in.js
 * migrated so far. At time of writing, it's been wrapped around most page level component to reduce risk
 * as much as possible for a first change in the area. It's unlikely that any page needs to wait for all
 * of these apis before first render, and teams for each of their pages should either:
 * 1. Remove the use of the higher order component (HOC) all together (no api responses are needed).
 * 2. Replace with call to withBlockingApis and some selectors (some api responses are needed)
 * 3. No2, but move the HOC to exactly where it is needed in the component tree, including multiple
 *    uses of it.
 * 4. Provide alternate implementation so the page component continues to work in all scenarios in
 *    conjunction with the various api loading states.
 */
const withLegacyBlockingApis = (WrappedComponent, fallbackOverride) =>
  withBlockingApis(
    WrappedComponent,
    [
      hasCustomerAccountsInitialLoadCompleted,
      hasCustomerSlotInitialLoadCompleted,
      hasGetMembershipStatusInitialLoadCompleted,
      hasTrolleyLoadCompleted,
      hasCustomerOrderStatsInitialLoadCompleted,
    ],
    fallbackOverride,
  );

const MealPlanner = withLegacyBlockingApis(MealPlannerComponent);

/*
  Loadable Components

  Switch loadable to import
  find:    const (.*) = loadable\(\(\) => import\((.*)\)\);
  replace: import $1 from $2;

  Switch import to loadable
  find:    import (.*) from (.*);
  replace: const $1 = loadable(() => import($2));

  Place loadable components here
  Please ensure all routes have:
  1. Skeleton loader (there is one by default which you can override for more control)
  2. A custom error boundary around the component if required, otherwise it will fall back to the App-wide error boundary"
*/

const noFallback = { fallback: null };

const UrgencyBanner = loadable(
  () =>
    import(
      /* webpackChunkName: "UrgencyBanner" */
      'components/UrgencyBanner'
    ),
  noFallback,
);
const CommonSiteBanner = loadable(
  () =>
    import(
      /* webpackChunkName: "CommonSiteBanner" */
      'components/CommonSiteBanner'
    ),
  noFallback,
);
const SitePinBar = loadable(
  () =>
    import(
      /* webpackChunkName: "SitePinBar" */
      'components/SitePinBar'
    ),
  noFallback,
);
const InstantCheckoutModal = loadable(
  () =>
    import(
      /* webpackChunkName: "InstantCheckoutModal" */
      'components/GoToCheckout/InstantCheckoutModal'
    ),
  noFallback,
);
const CommonSnackbar = loadable(
  () =>
    import(
      /* webpackChunkName: "CommonSnackbar" */
      'components/CommonSnackbar'
    ),
  noFallback,
);

const AdminAddressReduxForm = loadable(() => import('components/Address/Admin'));
const Browse = withLegacyBlockingApis(loadable(() => import('components/Browse')));
const CmsPage = withLegacyBlockingApis(loadable(() => import('components/CmsPage')));
const MarketingPreferences = withLegacyBlockingApis(
  loadable(() => import('components/MyAccount/MarketingPreferences')),
);

const ReturnToAdmin = loadable(() => import('components/ReturnToAdmin'));
const ReviewLogin = loadable(() => import('components/ProductDetails/Reviews/ReviewLogin'));
const RobotsRoutes = loadable(() => import('components/RobotsRoutes'), noFallback);

const SkipLink = loadable(() => import('components/SkipLink'), noFallback);

const FavouritesPage = withLegacyBlockingApis(loadable(() => import('components/Favourites/Page')));
const Featured = withLegacyBlockingApis(loadable(() => import('components/Featured')));
const ForgotPassword = withLegacyBlockingApis(loadable(() => import('components/ForgotPassword')));
const Interstitials = withLegacyBlockingApis(loadable(() => import('components/Interstitials')));
const MyAccount = withLegacyBlockingApis(loadable(() => import('components/MyAccount')));
const MyOrders = withLegacyBlockingApis(loadable(() => import('components/MyOrders/Page')));

const MyPaymentCards = withLegacyBlockingApis(loadable(() => import('components/MyPaymentCards')));
const ListsPage = withLegacyBlockingApis(loadable(() => import('components/Lists/Page')));
const Login = withLegacyBlockingApis(loadable(() => import('components/Login')));
const LoginInitiator = withLegacyBlockingApis(loadable(() => import('components/LoginInitiator')));
const Logout = withLegacyBlockingApis(loadable(() => import('components/Logout')));

const MultiSearchResults = withLegacyBlockingApis(
  loadable(() => import('components/MultiSearch/Page')),
);
const NotFound = withLegacyBlockingApis(loadable(() => import('components/NotFound/NotFound')));
const OfferDetails = withLegacyBlockingApis(
  loadable(() => import('components/Offers/OfferDetails')),
);
const OffersPage = withLegacyBlockingApis(loadable(() => import('components/Offers/OffersPage')));
const OffersTypePage = withLegacyBlockingApis(
  loadable(() => import('components/Offers/OffersTypePage')),
);
const OrderConfirmation = withLegacyBlockingApis(
  loadable(() => import('components/OrderConfirmation')),
);

const PaymentConfirmationPage = withLegacyBlockingApis(
  loadable(() => import('components/PaymentConfirmation/PaymentConfirmationPage')),
);

const RecipesPage = withLegacyBlockingApis(loadable(() => import('components/Recipes/Page')));
const RecipesLanding = withLegacyBlockingApis(loadable(() => import('components/RecipesLanding')));
const Registration = withLegacyBlockingApis(loadable(() => import('components/Registration')));
const ResetPassword = withLegacyBlockingApis(loadable(() => import('components/ResetPassword')));
const SearchResults = withLegacyBlockingApis(
  loadable(() => import('components/Search/SearchResults')),
);

const ShopFromPrevious = withLegacyBlockingApis(
  loadable(() => import('components/ShopFromPrevious/Page')),
);
const ShopFromPreviousInterstitial = withLegacyBlockingApis(
  loadable(() => import('components/ShopFromPreviousInterstitial')),
);
const ShoppingListPage = withLegacyBlockingApis(
  loadable(() => import('components/ShoppingList/Page')),
);
const Trolley = withLegacyBlockingApis(loadable(() => import('components/Trolley')));

const ViewOrder = withLegacyBlockingApis(loadable(() => import('components/ViewOrder/Page')));

const MyDetails = withLegacyBlockingApis(loadable(() => import('components/MyDetails')));
const MyPartnershipCardAndDetails = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/MyPartnershipCardAndDetails')),
);
const BecomeAMember = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/BecomeAMember')),
);
const JoinMyWaitrose = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/JoinMyWaitrose')),
);
const WelcomeToMyWaitrose = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/WelcomeToMyWaitrose')),
);
const LinkMyWaitroseCard = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/LinkMyWaitroseCard')),
);
const LinkMyWaitroseCardSuccess = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/LinkMyWaitroseCardSuccess')),
);
const MyWaitroseHubHomepage = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/MyWaitroseHubHomepage')),
);
const MyWaitroseBenefits = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/MyWaitroseBenefits')),
);
const MyWaitroseBenefitsFreeHotDrink = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/MyWaitroseBenefitsFreeHotDrink')),
);
const MyWaitroseBenefitsCaffeNero = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/MyWaitroseBenefitsCaffeNero')),
);
const MyWaitroseBenefitsDryCleaning = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/MyWaitroseBenefitsDryCleaning')),
);
const MyWaitroseBenefitsVitality = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/MyWaitroseBenefitsVitality')),
);
const MyWaitroseBenefitsWaitroseMagazines = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/MyWaitroseBenefitsWaitroseMagazines')),
);
const MyWaitroseVouchers = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/MyWaitroseVouchers')),
);
const MyWaitroseCardAndDetails = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/MyWaitroseCardAndDetails')),
);
const MyWaitroseCompetitions = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/MyWaitroseCompetitions')),
);
const OrderReplacementCard = withLegacyBlockingApis(
  loadable(() => import('components/OrderReplacementCard')),
);
const OrderReplacementCardAddress = withLegacyBlockingApis(
  loadable(() => import('components/OrderReplacementCardAddress')),
);
const OrderReplacementCardSuccess = withLegacyBlockingApis(
  loadable(() => import('components/OrderReplacementCardSuccess')),
);
const LeaveMyWaitrose = withLegacyBlockingApis(
  loadable(() => import('components/LeaveMyWaitrose')),
);

const AddAddressRouted = loadable(() => import('components/Address'), {
  resolveComponent: components => components.AddAddressRouted,
});

const SlotGrid = withLegacyBlockingApis(loadable(() => import('components/BookSlot/SlotGrid')));

const ServiceSelection = withLegacyBlockingApis(
  loadable(() => import('components/BookSlot/ServiceSelection'), {
    fallback: <Skeleton height="400px" />,
  }),
  <Skeleton height="400px" />,
);

const ServiceSelectionCollection = withLegacyBlockingApis(
  loadable(() => import('components/BookSlot/ServiceSelectionCollection')),
);

const Checkout = loadable(
  () =>
    import(
      /* webpackChunkName: "checkout" */
      'components/NewCheckout/CheckoutLoader'
    ),
  { fallback: <CheckoutWrapper /> },
);
const ResolveOrderPayment = loadable(
  () => import('components/ResolveOrderPayment/ResolveOrderPaymentLoader'),
  { fallback: <CheckoutWrapper title="Payment" /> },
);
const CampaignModals = withLegacyBlockingApis(
  loadable(() => import('components/BookSlot/CampaignModals')),
);

const ProhibitedPage = withLegacyBlockingApis(
  loadable(() => import('components/BookSlot/ProhibitedPage')),
);

const ProductPage = withLegacyBlockingApis(loadable(() => import('components/ProductPage')));

const TraCheckBlock = withLegacyBlockingApis(loadable(() => import('components/TraCheckBlock')));

const MealDealPage = withLegacyBlockingApis(
  loadable(() => import('components/MealDealPage/StrategicMealDealPage')),
);
const BundleBuilderPage = withLegacyBlockingApis(
  loadable(() => import('components/BundleBuilderPage')),
);

const CitrusAdsBuylistPage = withLegacyBlockingApis(
  loadable(() => import('components/CitrusAds/CitrusAdsBuylistPage')),
);
const CitrusAdsPreview = loadable(() => import('components/CitrusAds/CitrusAdsPreview'));
// End loadable components

const shortcutManager = new ShortcutManager(keymap);

class App extends PureComponent {
  static childContextTypes = {
    shortcuts: PropTypes.shape({}).isRequired,
  };

  getChildContext() {
    return {
      shortcuts: shortcutManager,
    };
  }

  componentDidMount() {
    const { readMonetateCookie } = this.props;
    readMonetateCookie();
  }

  componentDidCatch(error, errorInfo) {
    log(error, 'app', 'general', errorInfo);
  }

  render() {
    const features = getFeatureFlags();
    const { hasSitePinBar, location, helmetContext } = this.props;
    const minimalPage = pagesWithMinimalScaffolding.find(page =>
      (location.pathname + location.search).includes(page.url),
    );

    const pageWithoutAmendBar = pagesWithoutAmendBar.find(page =>
      (location.pathname + location.search).includes(page.url),
    );

    const checkoutPage = pagesWithCheckoutScaffolding.find(page =>
      (location.pathname + location.search).includes(page.url),
    );
    const pagesWithNoHeader = [
      ...(features.identity_enableOAuth2Web
        ? [{ url: urls.registration }, { url: urls.registrationAccountDetails }]
        : []),
    ];
    const noHeaderPage = pagesWithNoHeader.find(page =>
      (location.pathname + location.search).includes(page.url),
    );
    const orderConfirmationPage = (location.pathname + location.search).includes(
      urls.orderConfirmation,
    );
    const showMinimalHeaderSignIn = minimalPage && minimalPage.showMinimalHeaderSignIn;
    const classes = classnames({
      [styles.appWrapperMinimal]: minimalPage,
      hasSitePinBar,
    });
    const shouldAddLoyaltyCompetitionsRoute = features.loyalty_competitions;

    if (location.pathname.includes('/ecom/tra-check-block')) {
      return <TraCheckBlock />;
    }

    if (getAppType()) {
      return (
        <HelmetProvider context={helmetContext}>
          <ContentComponentLibraryProvider config={config}>
            <WebviewApp />
          </ContentComponentLibraryProvider>
        </HelmetProvider>
      );
    }
    // Any CRITICAL Providers MUST be added here and to the Webview above!
    return (
      <HelmetProvider context={helmetContext}>
        <LastLocationProvider>
          <WebVitalsReporter />
          <ExperimentsReporter />
          <TrolleyAnalyticsReporter />
          <DataProvider />
          <CookieAlert />
          <OptimisticTrolleyHandler />
          <ContentComponentLibraryProvider config={config}>
            <Head />

            <AppBanner />

            <UrgencyBanner />

            <SimpleErrorBoundary
              logger="App"
              section="top-level-error-boundary"
              errorComponent={<RefreshMessaging />}
            >
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route path={urls.reviewLogin} component={ReviewLogin} />
                <Route
                  exact
                  path={urls.checkout}
                  render={routeProps => (
                    <Fragment>
                      <ErrorBoundary logger="checkout-component" section="checkout">
                        <Checkout {...routeProps} />
                      </ErrorBoundary>
                    </Fragment>
                  )}
                />
                <Route
                  exact
                  path={`${urls.resolveOrderPayment}/:customerOrderId`}
                  component={ResolveOrderPayment}
                />
                <Route path={rootPath('/customer/address')} component={AdminAddressReduxForm} />
                <Route path={rootPath('/*/addresses/add')} component={AddAddressRouted} />
                <Route path={urls.citrusAdsPreview} component={CitrusAdsPreview} />
                <Route>
                  <div className={classes}>
                    <RobotsRoutes />
                    <div>
                      <SkipLink destination="#main">Skip to main content</SkipLink>
                    </div>
                    <ReturnToAdmin />
                    {!noHeaderPage &&
                      (minimalPage ? (
                        <SiteHeaderMinimal showMinimalHeaderSignIn={showMinimalHeaderSignIn} />
                      ) : (
                        <SiteHeader />
                      ))}
                    <CommonSiteBanner
                      show={
                        !!features.siteBanner &&
                        !checkoutPage &&
                        !minimalPage &&
                        !orderConfirmationPage
                      }
                    />
                    <main
                      className={minimalPage ? styles.appMainMinimal : styles.appMain}
                      role="main"
                      id="main"
                    >
                      <SimpleErrorBoundary
                        logger="App"
                        section="main-section-error-boundary"
                        errorComponent={<RefreshMessaging />}
                      >
                        <Switch>
                          <Route
                            exact
                            path={rootPath('/')}
                            render={() => <Redirect to={urls.groceriesHome} />}
                          />
                          <Route path={urls.favourites} component={FavouritesPage} />
                          <Route path={urls.lists} component={ListsPage} />
                          <Route
                            component={OrderConfirmation}
                            path={`${urls.orderConfirmation}/:customerOrderId`}
                          />

                          <Route
                            exact
                            path={`${urls.paymentConfirmationPage}/:customerOrderId`}
                            component={PaymentConfirmationPage}
                          />
                          <Route path={urls.interstitials} component={Interstitials} />
                          <Route
                            exact
                            path={rootPath('/shop')}
                            render={() => <Redirect to={urls.groceriesHome} />}
                          />
                          <Route path={urls.search} component={SearchResults} />
                          <Route path={urls.multiSearch} component={MultiSearchResults} />
                          <Route
                            path={rootPath('/shop/browse/offers/highlights/:offerIdentifier')}
                            component={OffersTypePage}
                          />
                          <Route path={urls.offers} component={OffersPage} />
                          <Route path={urls.browse} component={Browse} />
                          <Route
                            path={rootPath('/shop/offers/:offerId(-?\\d+)')}
                            component={OfferDetails}
                          />
                          <Route
                            path={`${urls.bundles}/:bundleName`}
                            component={BundleBuilderPage}
                          />
                          <Route
                            path={`${urls.mealDealPromoPath}/:mealDealId(\\d+)`}
                            component={MealDealPage}
                          />
                          <Route path={rootPath('/shop/featured')} component={Featured} />
                          <Route
                            path={rootPath('/products/:productName/:productId(\\d{6}-\\d+-\\d+)')}
                            render={routeProps => <ProductPage {...routeProps} />}
                          />
                          <Route path={urls.trolleyPage} component={Trolley} />
                          <Route
                            path={rootPath(
                              "/shopping-lists/:shoppingListName([a-zA-Z0-9'@£%&+!?., -]*)?/:shoppingListId([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[34][0-9a-fA-F]{3}-[89ab][0-9a-fA-F]{3}-[0-9a-fA-F]{12})",
                            )}
                            component={ShoppingListPage}
                          />
                          <Route
                            path={`${urls.serviceSelection}/:campaign?`}
                            component={ServiceSelection}
                          />
                          <Route
                            path={`${urls.bookCollectionSlot}/:campaign?`}
                            component={ServiceSelectionCollection}
                          />
                          <Route
                            path={urls.reselectCollectionService}
                            component={ServiceSelectionCollection}
                          />
                          <Route path={urls.bookslot} component={SlotGrid} />
                          <Route path={urls.login} component={LoginInitiator} />
                          <Route path={urls.signIn} component={Login} />
                          <Route path={urls.resetPassword} component={ResetPassword} />
                          <Route path={urls.forgotPassword} component={ForgotPassword} />
                          <Route
                            path={urls.marketingPreferences}
                            component={MarketingPreferences}
                          />
                          <Route exact path={urls.paymentCards} component={MyPaymentCards} />
                          <Route exact path={urls.myOrdersPage} component={MyOrders} />
                          <Route exact path={urls.shopFromPrevious} component={ShopFromPrevious} />
                          <Route
                            exact
                            path={urls.shopFromPreviousInterstitial}
                            component={ShopFromPreviousInterstitial}
                          />
                          <Route exact path={urls.viewOrder} component={ViewOrder} />
                          <Route exact path={urls.myAccount} component={MyAccount} />
                          <Route path={urls.myDetailsPage} component={MyDetails} />
                          <Route
                            path={urls.myPartnershipCardAndDetails}
                            component={MyPartnershipCardAndDetails}
                            exact
                          />
                          <Route
                            path={urls.becomeMyWaitroseMember}
                            component={BecomeAMember}
                            exact
                          />
                          <Route path={urls.joinMyWaitrose} component={JoinMyWaitrose} exact />
                          <Route
                            path={urls.joinMyWaitroseSuccess}
                            component={WelcomeToMyWaitrose}
                            exact
                          />
                          <Route path={urls.linkMyWaitrose} component={LinkMyWaitroseCard} exact />
                          <Route
                            path={urls.linkMyWaitroseSuccess}
                            component={LinkMyWaitroseCardSuccess}
                            exact
                          />
                          <Route
                            path={urls.myWaitrosePage}
                            component={MyWaitroseHubHomepage}
                            exact
                          />
                          <Route
                            path={urls.myWaitroseBenefits}
                            component={MyWaitroseBenefits}
                            exact
                          />
                          <Route
                            path={urls.myWaitroseBenefitsDryCleaning}
                            component={MyWaitroseBenefitsDryCleaning}
                            exact
                          />
                          <Route
                            path={urls.myWaitroseBenefitsFreeHotDrink}
                            component={MyWaitroseBenefitsFreeHotDrink}
                            exact
                          />
                          <Route
                            path={urls.myWaitroseBenefitsCaffeNero}
                            component={MyWaitroseBenefitsCaffeNero}
                            exact
                          />
                          <Route
                            path={urls.myWaitroseBenefitsVitality}
                            component={MyWaitroseBenefitsVitality}
                            exact
                          />
                          <Route
                            path={urls.myWaitroseBenefitsWaitroseMagazines}
                            component={MyWaitroseBenefitsWaitroseMagazines}
                            exact
                          />
                          <Route
                            path={urls.myWaitroseVouchers}
                            component={MyWaitroseVouchers}
                            exact
                          />
                          <Route
                            path={urls.myWaitroseCardAndDetails}
                            component={MyWaitroseCardAndDetails}
                            exact
                          />
                          {shouldAddLoyaltyCompetitionsRoute && (
                            <Route
                              path={urls.myWaitroseCompetitions}
                              component={MyWaitroseCompetitions}
                              exact
                            />
                          )}
                          <Route
                            path={urls.orderReplacementCard}
                            component={OrderReplacementCard}
                            exact
                          />
                          <Route
                            path={urls.orderReplacementCardAddress}
                            component={OrderReplacementCardAddress}
                            exact
                          />
                          <Route
                            path={urls.orderReplacementCardSuccess}
                            component={OrderReplacementCardSuccess}
                            exact
                          />
                          <Route
                            path={`${urls.citrusAdsBuylistPage}/:lineNumbers`}
                            component={CitrusAdsBuylistPage}
                          />
                          <Route path={urls.leaveMyWaitrose} component={LeaveMyWaitrose} />
                          <Route path={urls.registration} component={Registration} />
                          <Route path={urls.reschedulingProhibited} component={ProhibitedPage} />
                          <Route path={urls.logout} component={Logout} />
                          <Route path={urls.recipe} component={RecipesPage} />
                          <Route
                            exact
                            path={rootPath('/recipes/all-categories')}
                            render={props => <RecipesLanding {...props} isAllCategoriesPage />}
                          />
                          <Route
                            path={rootPath('/recipes/meal-planner/:page')}
                            component={MealPlanner}
                          />
                          <Route exact path={rootPath('/recipes')} component={RecipesLanding} />
                          {/* temporary use of RecipesLanding component TODO: WPIP-37372 */}
                          <Route path={urls.recipes} component={RecipesLanding} />
                          {cmsUrls.map(url => (
                            <Route key={url} path={url} component={CmsPage} />
                          ))}
                          <Route component={NotFound} />
                        </Switch>
                      </SimpleErrorBoundary>
                    </main>

                    {!pageWithoutAmendBar && <SitePinBar />}
                    {minimalPage ? <SiteFooterMinimal /> : <SiteFooter />}
                  </div>
                </Route>
              </Switch>
            </SimpleErrorBoundary>
            <CommonModal />
            <InstantCheckoutModal />
            <CommonSnackbar />
            <CampaignModals />
          </ContentComponentLibraryProvider>
        </LastLocationProvider>
      </HelmetProvider>
    );
  }
}

export default App;

App.propTypes = {
  hasSitePinBar: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  helmetContext: PropTypes.shape({}),
  readMonetateCookie: PropTypes.func.isRequired,
};

App.defaultProps = {
  helmetContext: {},
};
