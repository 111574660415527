import env from 'env/env';
import mapValues from 'lodash/mapValues';
import { rootPath } from 'utils/root-path';
import { SORT_URL_PURCHASE_FREQUENCY } from 'redux/modules/favourites/constants';

// Constants file for internal app URLs, simple key/value
//
// Usage:
//   import urls from 'constants/urls';
//   ...
//   <AnchorLink to={urls.favourites}>

const urls = mapValues(
  {
    becomeMyWaitroseMember: 'my-waitrose/become-a-member',
    bookCollectionSlot: 'bookslot/collection/selectcollectionservice',
    bookDeliverySlot: 'bookslot/delivery',
    bookEntertainingCollectionSlot: 'bookslot/collection/entertaining',
    bookEntertainingCollectionChristmasSlot: 'bookslot/collection/entertaining/christmas',
    bookGroceriesAndEntertainingCollectionSlot: 'bookslot/collection/groceriesandentertaining',
    bookGroceriesCollectionSlot: 'bookslot/collection/groceries',
    bookingASlotInfo: 'help-information/customer-service/booking-a-slot-and-placing-an-order',
    bookslot: 'bookslot',
    bookslotCollection: 'bookslot/collection',
    browse: 'shop/browse',
    bundles: 'shop/featured/bundles',
    checkout: 'checkout',
    blockTraFraudUser: 'tra-check-block',
    christmasHomePage: 'christmas',
    citrusAdsBuylistPage: 'shop/buylist',
    citrusAdsPreview: 'bannerx',
    coronation: 'shop/browse/groceries/the_kings_coronation',
    covidfaqs: 'help-information/customer-service/coronavirus',
    customerService: 'help-information/:path*',
    customerServiceContactUsPage: 'help-information/customer-service/contact-us',
    customerServiceHomePage: 'help-information/customer-service',
    customerServiceCoronavirus: 'help-information/customer-service/coronavirus',
    customerServiceShopPage: 'help-information/customer-service/shopping-in-store',
    deleteAddress: 'my-details/addresses/delete',
    easter: 'shop/browse/groceries/easter',
    entertainingChristmas: 'shop/browse/entertaining/christmas_food_to_order',
    entertainingHome: 'shop/browse/entertaining',
    favourites: 'favourites',
    favouritesOnOffer: `favourites?onOffer&sortBy=${SORT_URL_PURCHASE_FREQUENCY}`,
    featuredOffers: 'shop/featured/offers',
    forgotPassword: 'forgot-password',
    forgotPasswordExpired: 'forgot-password/expired',
    forgotPasswordLocked: 'forgot-password/locked',
    forgotPasswordSuccess: 'forgot-password/success',
    groceriesChristmas: 'shop/browse/groceries/christmas',
    groceriesHome: 'shop/browse/groceries',
    interstitials: 'beforeYouGo',
    joinMyWaitrose: 'my-waitrose/join',
    joinMyWaitroseSuccess: 'my-waitrose/join/welcome',
    leaveMyWaitrose: 'leave-my-waitrose',
    leaveMyWaitroseConfirmation: 'leave-my-waitrose/confirmation',
    linkMyWaitrose: 'my-waitrose/link-card',
    linkMyWaitroseSuccess: 'my-waitrose/link-card/welcome',
    lists: 'lists',
    login: 'login',
    logout: 'log-out',
    loyaltyExperiences: 'loyalty-experiences',
    marketingPreferences: 'myaccount/marketingpreferences',
    mealDealPromoPath: 'shop/featured/meal-deals',
    mealDealDineIn: 'shop/featured/offers/meal-deals',
    mothersDay: 'shop/browse/groceries/mothers_day',
    multiSearch: 'shop/multi-search',
    myAccount: 'myaccount',
    myDetailsPage: 'my-details',
    myOrdersPage: 'myaccount/my-orders',
    myPartnershipCardAndDetails: 'my-partnership/card-and-details',
    myWaitroseOffers: 'shop/featured/offers/my-waitrose-offers',
    myWaitrosePage: 'my-waitrose',
    myWaitroseBenefits: 'my-waitrose/benefits',
    myWaitroseBenefitsCaffeNero: 'my-waitrose/benefits/caffe-nero',
    myWaitroseBenefitsDryCleaning: 'my-waitrose/benefits/dry-cleaning',
    myWaitroseBenefitsFreeHotDrink: 'my-waitrose/benefits/free-tea-or-coffee',
    myWaitroseBenefitsWaitroseMagazines: 'my-waitrose/benefits/waitrose-magazines',
    myWaitroseBenefitsVitality: 'my-waitrose/benefits/vitality',
    myWaitroseCardAndDetails: 'my-waitrose/card-and-details',
    myWaitroseCompetitions: 'my-waitrose/competitions',
    myWaitroseVouchers: 'my-waitrose/vouchers',
    myWaitroseMigrationVouchers: 'mywaitrose/vouchers',
    new: 'shop/browse/groceries/new',
    newspapers: 'shop/browse/groceries/kitchen_dining_and_home/newsagents/newspapers',
    offerDetails: 'shop/offers',
    offers: 'shop/browse/offers',
    orderConfirmation: 'order-confirmation',
    orderReplacementCard: 'my-waitrose/order-replacement-card',
    orderReplacementCardAddress: 'my-waitrose/order-replacement-card/address',
    orderReplacementCardSuccess: 'my-waitrose/order-replacement-card/success',
    paymentCards: '/myaccount/paymentcards',
    paymentConfirmationPage: 'payment-confirmation',
    paymentFormIframe: 'assets/formbuilder.html',
    privacyNotice: 'help-information/privacy-notice',
    recipe: 'recipe/:path',
    recipes: '/recipes/:path*',
    registration: 'registration',
    registrationNewPrototype: 'registration/new-prototype',
    registrationAccountDetails: 'registration/account-details',
    registrationConfirmation: 'registration/confirmation',
    registrationSuccess: 'registration/success',
    replaceMyWaitroseCard: 'help-information/customer-service/forms/replacement-card',
    reselectCollectionService: 'bookslot/collection/reselect-service',
    resetPassword: 'reset-password',
    resetPasswordSuccess: 'reset-password/success',
    reschedulingProhibited: 'reschedulingprohibited',
    resolveOrderPayment: 'resolve-order-payment',
    reviewLogin: 'reviewlogin',
    rewards: 'rewards',
    rewardsJohnLewis: 'rewards/johnlewis',
    search: 'shop/search',
    serviceSelection: 'serviceselection',
    serviceSelectionChristmas: 'serviceselection/christmas',
    serviceSelectionEaster: 'serviceselection/easter',
    shopFromPrevious: '/myaccount/my-orders/order/shop-from-order/:customerOrderId(\\d+)',
    shopFromPreviousInterstitial: '/myaccount/my-orders/order/shop-from-order',
    signIn: 'sign-in',
    summer: 'shop/browse/groceries/summer',
    trolleyPage: 'shop/trolley',
    valentines: 'shop/browse/groceries/valentines',
    viewOrder: '/myaccount/my-orders/order/:customerOrderId(\\d+)',
    waitroseMagazine: 'products/waitrose-and-partners-food-magazine/065244-33064-33065',
    waitroseCookies: 'help-information/website-cookies',
    welcomeToMyWaitroseLinkCard: '/my-waitrose/link-card/welcome',
    wineBrowse: 'shop/browse/groceries/beer_wine_and_spirits/wine',
    // ^ add internal URLs here, root path will be prepended
    //   e.g. 'favourites' => '/ecom/favourites'
  },
  rootPath,
);

export const shopFromPreviousURL = (orderId: number | string) =>
  urls.shopFromPrevious.replace(':customerOrderId(\\d+)', orderId?.toString());

export const viewOrderURL = (orderId: number) =>
  urls.viewOrder.replace(':customerOrderId(\\d+)', orderId?.toString());
export const printOrderURL = (orderId: number) => `${viewOrderURL(orderId)}?print`;

// list of all AEM routes which are handled by a single fetcher/component
// if you page is a hybrid (only part AEM driven structure) you should not use this list
export const cmsUrls = [
  '/help-information/:path*',
  '/content/:path*',
  '/christmas/:path*',
  '/inspiration/:path*',
  '/seasonal/:path*',
  '/health/:path*',
  '/social-responsibility/:path*',
].map(key => rootPath(key));

const authServer = `/api/token-client-${env.apiEnv}/v1/auth`;

const extraUrls = {
  addressModals: {
    add: '#add-address',
    edit: '#edit-address',
    choose: '#choose-address',
  },
  homepage: '/',
  termsAndConditions:
    '/content/waitrose/en/corporate_information_home/corporate_information/legal_notices/terms_and_conditions.html',
  myWaitroseTermsAndConditions:
    '/corporate_information_home/corporate_information/legal_notices/my_waitrose_terms_and_conditions.html',
  myWaitroseWCS: '/content/waitrose/en/home/mywaitrose/mywaitrose_welcome.html',
  cmsBaseUrl:
    'https://ecom-su-static-prod.wtrecom.com/content/dam/waitrosegroceriescms/wcs/checkout/',
  branchFinder: '/find-a-store',
  loginOAuth2: authServer,
  registrationOAuth2: `${authServer}?prompt=login&screen_hint=signup&redirect=/ecom/registration/account-details`,
  // ^ add URLs not under '/ecom' here
};

Object.assign(urls, extraUrls);

export default urls as typeof urls & typeof extraUrls;
