import { decodeHttpEncodedQueryString } from 'wcs-integration/decodeHttpEncodedQueryString';
import { signOnAsCustomerFromUrlToken } from 'sign-on-as-customer';
import { loadStubs } from 'stubs';
import { dataLayer } from 'analytics/data-layer';
import { addToTrolleyListener } from 'redux/listeners/add-to-trolley';
import { loginListener } from 'redux/listeners/login';
import setSentryContextListener from 'redux/listeners/set-sentry-context-listener';

import { getIsPreloaded } from 'redux/modules/page/selectors';
import { isSignedOnAsCustomer } from 'redux/modules/sessions/selectors';

import {
  rehydrateServerGtmEvents,
  rehydrateServerMonetateEvents,
} from 'redux/modules/page/actions';

import { generateCustomerDataLayerEvent } from 'redux/modules/login/customer-datalayer-event';
import { saveLastNonAuthenticationPath } from 'redux/modules/login/actions/save-last-non-authentication-path';
import { clearError } from 'redux/modules/sessions/actions/clear-error';
import { sessionDetailsLoaded as sessionDetailsLoadedAction } from 'redux/modules/sessions/actions/session-details-loaded';
import { refreshSession } from 'redux/modules/sessions/refresh-session';
import { checkResetPasswordExpiryDate } from 'route-actions/check-reset-password-expiry-date';
import { getAllDecisions } from 'redux/modules/experiments/selectors';
import { trackExperimentView } from 'redux/modules/experiments/actions/track-experiment-view';
import { fetchOnServer } from 'route-data/fetch-on-server';
import history from 'utils/history';
import { PAGE_DATA_PRELOAD_IN_PROGRESS } from 'redux/modules/page/actions/types';
import { clientPageLoaded } from 'redux/modules/page/actions/client-page-loaded';

const preRenderActions = async store => {
  const { dispatch, getState } = store;

  decodeHttpEncodedQueryString();
  checkResetPasswordExpiryDate();

  dispatch(saveLastNonAuthenticationPath());
  await signOnAsCustomerFromUrlToken(dispatch);

  await loadStubs(getState());

  addToTrolleyListener(store);
  loginListener(store);
  setSentryContextListener(store);

  dispatch(rehydrateServerGtmEvents());
  dispatch(rehydrateServerMonetateEvents());

  let customerDataLoaded;
  // Extraction of key logic from useExperiments hook so it's function can be used outside of a React component
  const experimentId = 'renderBlockingApiRequests';
  const decisions = getAllDecisions(getState());
  const variant = decisions?.[experimentId];
  let dataFetchComplete;
  if (getIsPreloaded(getState())) {
    customerDataLoaded = Promise.resolve();
  } else {
    let sessionGenerated;
    ({ customerDataLoaded, sessionGenerated } = refreshSession(dispatch, getState, {
      dataLoad: variant !== 'unBlock',
    }));
    await sessionGenerated;
    // i.e. experiment is active
    if (variant === 'unBlock') {
      // Start initial api data load
      dataFetchComplete = (async () => {
        dispatch({ type: PAGE_DATA_PRELOAD_IN_PROGRESS });
        await fetchOnServer({ path: history.getLocation().pathname, url: undefined }, { dispatch });
        dispatch(clientPageLoaded());
      })();
    }
  }

  // i.e. experiment is active and either variant is in use
  if (variant) {
    dispatch(trackExperimentView({ id: experimentId, variant }));
  }

  const customerDataLayerEventPromise = (async () => {
    if (variant === 'unBlock') {
      await dataFetchComplete;
    } else {
      await customerDataLoaded;
    }
    dispatch(clearError());

    const dataLayerEventName = isSignedOnAsCustomer(getState()) ? 'sign_on_as_customer' : null;

    dispatch(sessionDetailsLoadedAction());
    return generateCustomerDataLayerEvent(getState(), dataLayerEventName);
  })();

  // The data layer abstraction understands the promise and waits for it to resolve giving the option
  // to allow the rest of the function to continue.
  dataLayer.push(customerDataLayerEventPromise);

  if (variant !== 'unBlock') {
    // Customer logged in data is waited to be returned i.e. the "old" behaviour
    await customerDataLayerEventPromise;
  }
};

export default preRenderActions;
