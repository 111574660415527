import addColumnComponentToContentResponse from 'components/ContentLocation/add-column-component-to-content-response';
import { deepFreeze } from 'utils/deepFreeze';

import {
  EXPERIENCE_FRAGMENT_SUBMIT,
  EXPERIENCE_FRAGMENT_SUBMIT_FAIL,
  EXPERIENCE_FRAGMENT_SUBMIT_SUCCESS,
  ExperienceFragment,
} from 'redux/modules/experience-fragments/actions/types';

export const initialState = deepFreeze({});

type ActionType = {
  type:
    | typeof EXPERIENCE_FRAGMENT_SUBMIT
    | typeof EXPERIENCE_FRAGMENT_SUBMIT_SUCCESS
    | typeof EXPERIENCE_FRAGMENT_SUBMIT_FAIL;
  experienceFragmentKey: string;
  result?: ExperienceFragment;
  applyColumnTransformation?: boolean;
};

export default (
  state: Record<string, Record<string, unknown>> = initialState,
  action: ActionType,
) => {
  switch (action.type) {
    case EXPERIENCE_FRAGMENT_SUBMIT_SUCCESS: {
      const locations = action?.result?.locations ?? {};

      return {
        ...state,
        [action.experienceFragmentKey]: {
          ...action.result,
          locations: action?.applyColumnTransformation
            ? addColumnComponentToContentResponse(locations)
            : locations,
        },
      };
    }
    case EXPERIENCE_FRAGMENT_SUBMIT_FAIL: {
      return {
        ...state,
        [action.experienceFragmentKey]: {
          ...state[action.experienceFragmentKey],
        },
      };
    }
    default: {
      return state;
    }
  }
};
